<template>
    <div>
        <table width="100%" class="vs-table vs-table--tbody-table ">
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Warehouse Code
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.warehouse.code + ' - ' + this.form.warehouse.name }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Shipment Number
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.shipment_number }}
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Supplier
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.supplier.code + ' - ' + this.form.supplier.name }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Delivery Note Number
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.delivery_order_number }}
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Purchase Data
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.code }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Delivery Note Date
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + formatDate(this.form.dn_date) }}
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Receive Date
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + formatDate(this.form.date) }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Notes
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.notes }}
                </td>
            </tr>
        </table>

        <table width="100%" class="vs-table vs-table--tbody-table print">
            <thead class="vs-table--thead">
                <tr>
                    <th colspan="1" style="text-align: center;">Supplier</th>
                    <th colspan="2" style="text-align: center;">Internal</th>
                    <th colspan="2" style="text-align: center;"> Receive Data </th>
                    <th colspan="2" style="text-align: center;"> Conversion Data </th>
                </tr>
                <tr>
                    <th>
                        <div>SKU Code</div>
                    </th>
                    <th>
                        <div>SKU Code</div>
                    </th>
                    <th>
                        <div>SKU Description</div>
                    </th>
                    <th>HU</th>
                    <th>Qty</th>
                    <th>
                        <div>HU</div>
                    </th>
                    <th>
                        <div>Qty</div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in form.purchase_receive_lines" :key="index" style="padding-bottom;: 5px">
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.supplier_sku_code }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.internal_sku_code }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.item_name }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.hu_name }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.quantity }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        <table>
                            <tr v-for="(line, idx) in item.hu_line" :key="idx">
                                <td>
                                    {{ line.item_unit.name }}
                                </td>
                            </tr>
                        </table>
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        <table>
                            <tr v-for="(line, idx) in item.hu_line" :key="idx">
                                <td>
                                    {{ line.quantity }}
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="flex justify-center w-full pt-6 print-button">
            <vs-button style="margin:'0 auto'" color="primary" @click="handlePrint()">Print</vs-button>
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    components: {
    },
    mounted() {
        this.id = this.$route.params.id;
        this.mode = this.$route.params.mode;
        this.initData();
    },
    data() {
        return {
            dialog: {
                save: false,
            },
            id: null,
            attachment: null,
            form: {
                id: null,
                purchase_order_id: null,
                warehouse: null,
                supplier: null,
                status: "0",
                dn_date: moment().toDate(),
                purchaseData: null,
                code: null,
                date: null,
                notes: null,
                delivery_order_number: null,
                purchase_date: null,
                shipment_number: null,
                purchase_receive_lines: null,
            },
        }
    },
    methods: {
        async handlePrint() {
            await window.print();
        },
        initData() {
            this.$vs.loading();
            this.$http
                .get(`${this.$store.state.inbound.purchaseOrder.baseUrlPath}/receive/edit-dto/${this.id}`)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.form.id = resp.data.id;
                        this.form.purchase_order_id = resp.data.purchase_order_id;
                        this.form.warehouse = resp.data.warehouse;
                        this.form.supplier = {
                            code: resp.data.supplier_code,
                            name: resp.data.supplier_name,
                        };
                        this.form.dn_date = resp.data.dn_date;
                        this.form.code = resp.data.code;
                        this.form.notes = resp.data.notes;

                        if (resp.data.status !== "") {
                            this.form.status = resp.data.status;
                        }
                        this.form.purchase_date = resp.data.purchase_date;
                        this.form.date = resp.data.date;
                        this.form.delivery_order_number = resp.data.delivery_order_number;
                        this.form.shipment_number = resp.data.shipment_number;
                        var mapLine = new Map()
                        if (resp.data.line_dto) {
                            resp.data.line_dto.forEach((item) => {
                                if (item.is_damaged == 5) {
                                    item.hu_line = [];
                                    mapLine.set(item.purchase_order_line_id, item);
                                }
                            });

                            resp.data.line_dto.forEach((item) => {
                                if (item.is_damaged == 3) {
                                    const line = mapLine.get(item.purchase_order_line_id);

                                    item.item_unit = {
                                        id: item.item_unit_id,
                                        name: item.hu_name,
                                        amount_uom: item.amount_uom
                                    }

                                    if (line.hu_line) {
                                        line.hu_line.push(item);
                                    } else {
                                        line.hu_line = [item];
                                    }
                                    mapLine.set(item.purchase_order_line_id, line);
                                }
                            });
                            mapLine.forEach((item, key) => {
                                if (item.hu_line.length < 1) {
                                    const parent = Object.assign({}, item);
                                    delete item.hu_line;
                                    item.quantity = 0;
                                    item.hu_name = null;
                                    item.is_damaged = 3;
                                    parent.hu_line = [item];
                                    mapLine.set(key, parent);
                                }
                            });
                            var prLine = []
                            mapLine.forEach((item, key) => {
                                prLine.push(item)
                                item.key = key;
                            });
                        }
                        this.form.purchase_receive_lines = prLine;
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('DD MMM YYYY');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                return Number(val).toLocaleString("id-ID");
            };
        },
    },
}
</script>
<style>
/* Set the page size to A4 and the orientation to landscape */
@page {
    size: A4 landscape;
}

/* Set the font size to 12px for printing */
@media print {
    body {
        font-size: 12px;
        background: none !important;
    }

    * {
        background: none !important;
    }

    header,
    footer,
    .print-button,
    #content-area * :not(.content-wrapper *) {
        display: none !important;
        visibility: hidden !important;
    }

    .router-view,
    .router-content {
        padding: 0 !important;
        margin: 0 !important;
    }
}

table.print {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
}

table.print td,
table.print th {
    border: 1px solid #abab !important;
    text-align: left;
    padding: 8px;
}

table.print td>table * {
    border: 0px !important;
}
</style>
